import React from 'react'
import styled from '@emotion/styled'
import Layout from '../components/layout'
import Block from '../components/block'

const StyledSection = styled('section')({
    
});

const AboutPage = () => {

    return (
        <Layout>
            <Block>
                <StyledSection>
                    <h1>About</h1>
                    <p>
                       My name is Ryan Balla, I'm a Software Engineer that works and lives in Chicago. I build teams through a mixture of servant leadership, empathy, and coffee. 
                    </p>
                    <p>
                        On the side I craft model worlds though my creations in model railroading.</p>
                    <p>This site is inspired by a long lost railroad, let me know if you figure out which one.
                    </p>
                </StyledSection>
            </Block>
        </Layout>
    )   
}

export default AboutPage